import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import {
  LookupStatus,
  useLookup,
} from '@rsa-digital/evo-shared-components/hooks/useLookup';
import promotionsClient from 'apiHelpers/promotionsClient';
import React from 'react';
import CoverStartDate from 'components/CoverStartDate';
import InputWithApplyAndPanel from 'components/InputWithApplyAndPanel';
import { FormDetails } from 'forms/AboutYouAndYourPetForm/types';
import NumberOfPetsQuestion from 'forms/AboutYouForm/NumberOfPetsQuestion';
import { ProductId } from 'helpers/businessConstants';
import {
  PageTitle,
  trackAPIError,
  trackFormTextFieldFocus,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { useOptimizelyGradualRevealExperimentVariation } from 'helpers/optimizelyVariation';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { initialPromoCodeLookup, usePolicyDetails } from 'state/formData/policyDetails';
import useAboutYourPolicyQuestions from './questions';
import { isPromoCodeBlank } from './validation';

type AboutYourPolicyFormProps = {
  formValidation: {
    getError: FieldFunction<FormDetails, string | undefined>;
    getWarning: FieldFunction<FormDetails, string | undefined>;
    showValidation: FieldFunction<FormDetails, void>;
  };
};

const AboutYourPolicyForm: React.FC<AboutYourPolicyFormProps> = ({ formValidation }) => {
  const { getError, getWarning, showValidation } = formValidation;

  const questions = useAboutYourPolicyQuestions();
  const [policyDetails, updatePolicyDetails] = usePolicyDetails();

  const isGradulaRevealExperiment = useOptimizelyGradualRevealExperimentVariation();

  // If the any of the Pets are too young at the current date, we restrict the date picker
  // to ensure they are valid at the cover start date and display a special message.

  const {
    performLookup: performPromoCodeLookup,
    getLookupError: getPromoCodeLookupError,
  } = useLookup(
    policyDetails.promoCode,
    (update) =>
      updatePolicyDetails({
        promoCode: update,
      }),
    promotionsClient.promoCode,
    () => null,
    questions.promoCode.errorMessages,
    trackAPIError
  );

  // ========================================================================================
  // =       Optimizely A/B Experiment - Start Cover Date Panel vs Calendar                 =
  // ========================================================================================

  // Hard coded the variation #1 value instead of getting from ENV variables, for the ticket -  PE-372 - Policy start date vs calendar AB Test - Hardcoding.

  const optimizelyCoverStartDateVariation = 'Variation #1';
  const isOptimizelyNewVariation = optimizelyCoverStartDateVariation === 'Variation #1';

  // We show/hide calendar initially based on the value of coverStartDateVariation:
  // - If not isOptimizelyNewVariation  => We initially SHOW the calendar
  // - If isOptimizelyNewVariation      => We initially HIDE the calendar

  const quote = useCurrentQuote();

  return (
    <>
      {isGradulaRevealExperiment && (
        <NumberOfPetsQuestion
          formValidation={{
            getError,
            getWarning,
            showValidation,
          }}
        />
      )}
      <CoverStartDate
        pageTitle={PageTitle.AboutYouAndYourPet}
        isOptimizelyNewVariation={isOptimizelyNewVariation}
      />
      {ProductId.DIRECT === quote.productId && (
        <InputWithApplyAndPanel
          id="promoCodePanel"
          inputId="promoCode"
          addAriaLabel="Apply promotional code"
          removeAriaLabel="Remove promotional code"
          question={questions.promoCode}
          errorText={getError('promoCode')}
          lookupStatus={policyDetails.promoCode.status}
          text={{
            successPanel: questions.promoCode.successPanelText,
            errorPanel: getPromoCodeLookupError() || '',
          }}
          value={policyDetails.promoCode.lookupKey}
          onChange={(e) =>
            updatePolicyDetails({
              promoCode: {
                ...initialPromoCodeLookup,
                lookupKey: e.target.value.toUpperCase().replaceAll(' ', ''),
              },
            })
          }
          placeholder={questions.promoCode.placeholder}
          onFocus={trackFormTextFieldFocus('promoCode')}
          maxLength={12}
          loading={policyDetails.promoCode.status === LookupStatus.InProgress}
          onKeyDown={(e) => {
            if (
              e.key === 'Enter' &&
              !(
                isPromoCodeBlank(policyDetails) ||
                policyDetails.promoCode.status === LookupStatus.InProgress
              )
            ) {
              e.preventDefault();
              performPromoCodeLookup();
            }
          }}
          onApply={() => {
            performPromoCodeLookup();
            trackTextButtonClick(PageTitle.AboutYouAndYourPet, 'Apply promocode');
          }}
          onRemove={() => {
            updatePolicyDetails({
              promoCode: initialPromoCodeLookup,
            });
            trackTextButtonClick(PageTitle.AboutYouAndYourPet, 'Remove promocode');
          }}
          disableApply={isPromoCodeBlank(policyDetails)}
          applySuccess={policyDetails.promoCode.status === LookupStatus.Success}
        />
      )}
    </>
  );
};

export default AboutYourPolicyForm;
