import { petApiService } from './apiService';

type PromotionsClient = {
  promoCode: (promoCode: string) => Promise<void>;
};

const promotionsClient: PromotionsClient = {
  promoCode: (promoCode: string) => petApiService.get('promocode', { promoCode }),
};

export default promotionsClient;
